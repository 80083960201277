/* ------------------------------------------------- */
/* AZB Supplemental Code */
/* The following code was not found in AZ Bootstrap but is necessary for correct styling */

/* Padding on top of home icon in mobile flyout menu */
a.btn.btn-redbar {
  padding: .5rem 0 0 0;
}

/* Site ident / logo responsive behavior */
.qs-site-logo {
    margin: 40px 0;
    margin: 2.5rem 0;
}

@media (min-width: 576px) {
    .qs-site-logo {
        margin:22px 0;
        margin: 1.375rem 0;
    }
}

@media (min-width: 768px) {
    .qs-site-logo {
        margin:31px 0;
        margin: 1.938rem 0;
    }
}

@media (min-width: 992px) {
    .qs-site-logo {
        margin:29px 0;
        margin: 1.813rem 0;
    }
}

@media (min-width: 1200px) {
    .qs-site-logo {
        margin:31px 0;
        margin: 1.938rem 0;
    }
}

/* Blue heading nav */
.reg-page-title {
    position: relative;
    display: block;
    width: 100%;
}

.reg-page-title h1 {
    padding: 3rem 0;
    margin: 0;
    padding-left: 1rem;
    font-family: MiloWeb,Verdana,Geneva,sans-serif;
    font-weight: 500;
    color: #ffffff;
}

.reg-page-title::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    z-index: -1;
    background-color: #0c234b;
    background-image: url("https://cdn.digital.arizona.edu/lib/arizona-bootstrap/master/css/img/triangles-top-left-blue.svg");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: auto;
    background-blend-mode: initial;
}

/* ------------------------------------------------- */
/* NAV / MENUS */

.navbar {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: #eef1f1;
}

.nav-stacked ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-stacked > li {
  border: solid 1px #e2e9eb;
}

.nav-stacked > li a {
  color: #0c234b;
}

.nav-stacked > li:last-child {
  border-top: 0;
}

.nav-stacked li ul li a {
  padding-left: 25px;
}

.nav-stacked li ul li a {
  font-weight: 400;
}

.navbar-toggler {
  width: 100%;
  text-align: left;
  padding: 1rem .75rem;
  border-bottom: 1px solid #eef1f1;
  outline: none;
  font-size: 1.15em;
}

.navbar-toggler.dropdown-toggle:after {
  margin: 0;
}

.navbar-toggler:focus {
  outline: none;
}

.navbar-toggler-icon {
  width: 100%;
  height: auto;
}


/* ------------------------------------------------- */
/* MAIN PAGES */


.home_BG {
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0;
  background-image: url('/images/ds_home_splash_l3.png');
  background-position: 50% 50%;
  background-repeat: repeat;
  background-attachment: fixed;
  z-index:1;
}

.home_BG_2 {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: rgb(12,35,75);
  background: linear-gradient(90deg, rgba(12,35,75,1) 0%, rgba(12,35,75,1) 48%, rgba(12,35,75,0) 48%, rgba(12,35,75,0) 100%);
}

@media (max-width: 991px) {
  .home_BG, .home_BG_2 {
    display: none;
  }


}

@media (min-width: 992px) {
  .bgHome_l1 {
    position: relative;
    background-image: linear-gradient(-17deg, #1E5288 0%, rgba(30,82,136,0.00) 63%);
  }

  .bgHome_l2 {
    position: relative;
    z-index: 2;
      background-image: url('/images/ds_home_splash_l2@2x.png');
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: right -9em center;

  }
}

@media (min-width: 1200px) {
  .bgHome_l2 {
    background-position: center center;
  }
}

@media (min-width: 576px) { /* AZ Bootstrap override, card-deck responsive flex issue) */
  .card-deck-responsive-fix .card {
    flex: 0 0 100%;
    margin-bottom: 15px;
  }
}

@media (min-width: 992px) {
  .card-deck-responsive-fix .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .trellis-vision-bg {
      background-image: url("/images/trellis_vision.png");
      background-repeat: no-repeat;
      background-position: top 1em right;
  }
}

.trellis-seed-bg {
  background-image: url("/images/trellis_seed.svg");
  background-repeat: no-repeat;
  background-position: top 16px right 16px;
}


/* Documentation: Color */

.colorSwatches {
  display: flex;
  width: 100%;
  margin: 1.2em 0;
}

.colorSwatches .swatch {
  flex-grow: 1;
  min-height: 123px;
  padding: 18px;
}

.colorSwatches .swatchName,
.colorSwatches .swatchValue {
  display: block;
  font-size: 1.25em;
  line-height: 1.15em;
}

.colorSwatches .swatchName {
  font-weight: bold;
}

.colorSwatches .swatchValue {
  text-transform: uppercase;
}

.page_title_example {
  border-top: 1px dashed #F840FF;
  border-bottom: 1px dashed #F840FF;
}

.buttonExamples .title_cell p,
.buttonExamples .header {
  margin: 0;
}

.buttonExamples .title_cell,
.buttonExamples .header {
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .colorSwatches { flex-direction: column; }
  .colorSwatches .swatch { min-height: 90px; }
  .colorSwatches .swatchName,
  .colorSwatches .swatchValue { font-size: 1em; }
}


/* Documentation: Buttons */

.btn-wrapper {
  padding: 8px;
}

